<template>
  <div>
    <div v-for="(i, index) in formData" :key="index">
      <el-form :model="i" ref="formData" label-width="100px">
        <div class="border mab">
          <div>
            <div class="title flex flex-between">
              <div>拜访考察{{ index + 1 }}</div>
              <div
                class="active-color"
                v-if="index != 0"
                @click="removeForm(index)"
              >
                删除
              </div>
            </div>
            <div class="content">
              <div>
                <el-form-item
                  class="flex-1"
                  label="项目人员："
                  :prop="`formData.${index}.cstName`"
                  :rules="[{ required: true, message: '项目人员不能为空' }]"
                >
                  <div class="el-date-editor--date">
                    <el-input v-model="i.cstName"></el-input>
                  </div>
                </el-form-item>
              </div>
              <div>
                <el-form-item
                  class="flex-1"
                  label="陪同人员："
                  :prop="`formData.${index}.receptionist`"
                  :rules="[{ required: true, message: '陪同人员不能为空' }]"
                >
                  <div class="el-date-editor--date">
                    <el-input v-model="i.receptionist"></el-input>
                  </div>
                </el-form-item>
              </div>
              <el-row>
                <el-col :span="11">
                  <el-form-item
                    class="flex-1"
                    label="时间："
                    :prop="`formData.${index}.time`"
                    label-width="''"
                    :rules="[{ required: true, message: '时间不能为空' }]"
                  >
                    <div>
                      <el-date-picker
                        v-model="i.time"
                        value-format="yyyy-MM-dd"
                        type="date"
                        placeholder="选择日期"
                      >
                      </el-date-picker>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="12" :offset="1">
                  <el-form-item
                    class="flex-1"
                    label="拜访考察事由:"
                    label-width="''"
                    :prop="`formData.${index}.reason`"
                    :rules="[
                      { required: true, message: '拜访考察事由不能为空' },
                    ]"
                  >
                    <div class="el-date-editor--date">
                      <el-input
                        v-model="i.reason"
                        placeholder="一句话描述"
                      ></el-input>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item
                v-for="lab in labels"
                :key="lab.id"
                :label="lab.name"
                class="flex-1 attachment-item"
                label-width="''"
                :rules="[
                  {
                    required: lab.required ? true : false,
                    message: `${lab.name}不能为空`,
                  },
                ]"
              >
                <el-upload
                  :action="url"
                  :on-success="
                    (response, file, fileList) =>
                      handleSuccess(file, lab.name, index)
                  "
                  :on-remove="
                    (response, file, fileList) => handleRemove(response, index)
                  "
                  :file-list="lab.list | fileDeal"
                >
                  <div class="active-color border addBtn">添加附件</div>
                </el-upload>
              </el-form-item>
              <el-form-item
                label="备注:"
                :prop="`formData.${index}.remark`"
                :label-width="'60px'"
              >
                <el-input type="textarea" v-model="i.remark"></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div class="active-color border addBtn" @click="addVisit">
      +添加拜访考察
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { URL } from "@/util/request";
import { mapState } from "vuex";
import { getAttachment } from "@/api/crm/workbench";
export default {
  data() {
    return {
      url: URL.API_ATTACHMENT_UPLOAD,
      uploadNum: 0,
      labels: [
        {
          name: "拜访考察方案",
          id: 1,
        },
        {
          name: "现场照片",
          id: 4,
          required: true,
        },
        {
          name: "洽谈结果",
          id: 2,
          required: true,
        },

        {
          name: "企业资料",
          id: 5,
        },
      ],
    };
  },
  watch: {
    uploadNum(newVal) {
      if (newVal == 0) {
        this.$emit("isClick", true);
      }
    },
  },
  computed: {
    // ...mapState({
    //   formData: (state) => state.visit.detailData,
    // }),
    formData() {
      if (this.$store.state.visit.detailData.length > 0) {
        this.$store.state.visit.detailData.forEach((val) => {
          this.labels.forEach((lab) => {
            lab.list = val.lstAttachment.filter(
              (x) => x.docTypeName == lab.name
            );
          });
        });
      }
      return this.$store.state.visit.detailData;
    },
  },
  filters: {
    fileDeal(data) {
      //数据处理
      data.forEach((val) => {
        val.name = val.originalName;
        val.url = URL.static + val.fileName;
      });
      return data;
    },
  },
  mounted() {},
  methods: {
    clearFiles() {
      this.$refs["my-upload"].clearFiles();
    },
    handleRemove(file, index) {
      const filterIndex = this.formData[index].lstAttachment.findIndex(
        (x) => x.uid == file.uid
      );
      this.formData[index].lstAttachment.splice(filterIndex, 1);
    },
    removeForm(index) {
      this.formData.splice(index, 1);
    },
    handleSuccess(file, value, index) {
      getAttachment({ name: value }).then((res) => {
        if (res.status == 200) {
          if (!this.formData[index].lstAttachment) {
            this.formData[index].lstAttachment = [];
          }
          this.formData[index].lstAttachment.push({
            docTypeName: value,
            originalName: file.name,
            fileName: file.response.data,
            docTypeId: res.data,
            uid: file.uid,
            name: file.name,
          });
        }
      });
    },
    addVisit() {
      this.formData.push({
        cstName: "",
        receptionist: "",
        time: null,
        reason: "",
        remark: "",
        lstAttachment: [],
      });
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.active-color {
  cursor: pointer;
}
.addBtn {
  height: 30px;
  line-height: 30px;
  margin-top: 0;
}
.title {
  background: #f6f8fd;
  color: #374567;
  font-size: 14px;
  padding: 8px 24px;
  margin-bottom: 10px;
}
.content {
  padding: 16px 12px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
::v-deep .el-date-editor--date .el-input__inner {
  border-color: #aab0be;
  height: 40px !important;
  border-radius: 5px;
  padding: 10px auto;
}
.mab {
  margin-bottom: 30px;
}
::v-deep .el-upload {
  width: 100%;
}
.attachment-item {
  margin-bottom: 0px;
}
</style>