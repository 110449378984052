<template>
  <div v-if="dialogVisible">
    <el-dialog
      :title="getByCheck.name"
      :visible.sync="dialogVisible"
      :width="'75%'"
      @close="close"
      :destroy-on-close="true"
    >
      <h2 class="project-title">
        项目名称：{{
          detailData.projectInfo && detailData.projectInfo.projName
        }}
      </h2>
      <div class="border-bottom step-group-box">
        <step-template></step-template>
      </div>
      <div class="content-box" v-if="getByCheck.type != 'register'">
        <base-info></base-info>
      </div>
      <div v-for="item in componentGroup" :key="item.type">
        <div v-if="getByCheck.type == item.type" class="content-box">
          <component :is="item.name"></component>
        </div>
      </div>
      <slot name="footer"></slot>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import StepTemplate from "../components/steps";
import { Step2Template, Step1Template, Step3Template } from "./stepGroup";
import baseInfo from "@/views/workbench_module/attract_project/components/detail/baseInfo";
export default {
  components: {
    StepTemplate,
    Step1Template,
    Step2Template,
    Step3Template,
    baseInfo,
  },
  computed: {
    ...mapGetters(["getByCheck", "getByActive"]),
    ...mapState({
      dialogVisible: (state) => state.projectTable.dialogVisible,
      detailData: (state) => state.projectRegister.detailData,
    }),
  },
  methods: {
    close() {
      this.$store.commit("SET_DIALOGVISIBLE", false);
      this.$store.commit("RESTE");
    },
  },
  data() {
    return {
      componentGroup: [
        {
          name: "Step1Template",
          type: "register",
        },
        {
          name: "Step2Template",
          type: "visit",
        },
        {
          name: "Step3Template",
          type: "site-select",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .btns {
  display: flex;
  justify-content: flex-end;
  .red {
    color: #fc555e;
    border: 1px solid #fc555e;
  }
}
.project-title {
  text-align: center;
  padding: 10px 0 40px;
}
.step-group-box {
  padding-bottom: 24px;
  margin-bottom: 30px;
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #e1e6f0;
}
.content-box {
  padding: 0 130px;
}
::v-deep .btns {
  transform: translateX(60px);
}
::v-deep .btns {
  margin-top: 30px;
}
</style>