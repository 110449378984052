<template>
  <div class="flex step-box">
    <div
      @click="stepEvent(item,index)"
      v-for="(item, index) in steps"
      :key="item.name"
      class="flex step_item"
      :class="
        item.status == 2 ? 'complete' : active == index ? 'active' : 'disabled'
      "
    >
      <span class="step_icon" v-if="item.status != 2">{{ index + 1 }}</span>
      <img
        class="step_icon"
        v-else
        src="@/assets/images/workbench/attract_project/complete-icon.png"
        alt=""
      />
      <span class="step_title">{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed:{
        ...mapState({
            steps:state=>state.step.list,
            active:state=>state.step.active,
        }),
        
    },
    mounted(){
    },
    methods:{
      stepEvent(item,i){
        if(item.status==0 && this.steps[this.active].type!=item.type) return;
        this.$store.commit('SET_CHECK',i)
      }
    }
};
</script>

<style lang="scss" scoped>
.step-box {
  justify-content: center;
  .step_item {
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    font-weight: 400;
    .step_icon {
      display: inline-block;
      width: 31px;
      height: 31px;
      border-radius: 50%;
      margin-right: 8px;
      text-align: center;
      line-height: 32px;
      border: 1px solid;
    }
  }
  .step_item.disabled {
    color: #7b849a;
    .step_icon {
      border-color: #7b849a;
    }
  }
  .step_item.active {
    color: #374567;
    .step_icon {
      background: #00a0e9;
      border-color: #00a0e9;
      color: #fff;
    }
  }
  .step_item.complete {
    color: #00a0e9;
    font-weight: 500;
    .step_icon {
      border: 0;
    }
  }
  .step_item::after {
    content: " ";
    width: 64px;
    height: 1px;
    background: #374567;
    margin: 0 16px;
  }
  .step_item:last-child::after {
    display: none;
  }
}
</style>