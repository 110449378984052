
<template>
  <div class="dialog-content-box content-create-detail">
    <div class="dialog-content" v-if="detailData">
      <div class="flex marb">
        <div class="flex-1 flex">
          <div class="label">选址类型：</div>
          <div class="value-txt">
            {{ detailData.landType | filterLandType }}
          </div>
        </div>
        <div class="flex-1 flex">
          <div class="label">面积需求：</div>
          <div class="value-txt">{{ detailData.area }}</div>
        </div>
      </div>
      <div
        v-for="(item, index) in reverseList(detailData.lstChooseAddr)"
        :key="item.id"
        class="border item marb"
      >
        <div class="title">
          选址信息{{ detailData.lstChooseAddr.length - index }}
        </div>
        <ul class="file-group content">
          <li class="flex">
            <div class="label">载体/土地名称：</div>
            <div class="value-txt">{{ item.addrName }}</div>
          </li>
          <li class="flex">
            <div class="flex-1">
              <li class="flex-1 flex">
                <div class="label">时间：</div>
                <div class="value-txt">{{ item.time }}</div>
              </li>
              <li class="flex-1 flex">
                <div class="label">实测面积：</div>
                <div class="value-txt">{{ item.area }}</div>
              </li>
            </div>
            <div class="flex-1 flex">
              <div class="label">位置：</div>
              <div class="value-txt">{{ item.location }}</div>
            </div>
          </li>
          <li>
            <div class="label">选址图件</div>
            <a
              class="value active-color"
              :href="staticURL + i.fileName"
              v-for="i in dealData(index, '选址图件')"
              :key="i.fileName"
              >{{ i.originalName }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="dialog-footer">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { URL } from "@/util/request";
import { mapState } from "vuex";
export default {
  data() {
    return {
      companyListFiles: [],
      contentListFiles: [],
      staticURL: URL.static,
    };
  },
  computed: {
    ...mapState({
      detailData: (state) => state["site-select"].detailData,
      labels: (state) => state["site-select"].labels,
    }),
  },
  filters: {
    filterLandType(text) {
      switch (text) {
        case 1:
          return "征地类";
        case 2:
          return "现有厂房入驻类";
        case 3:
          return "平台类";
      }
    },
  },
  mounted() {
    this.$store.dispatch("site-select/getSiteSelect");
  },
  methods: {
    reverseList(data) {
      let list = JSON.parse(JSON.stringify(data));
      return list.reverse();
    },
    dealData(index, name) {
      if (!this.detailData.lstChooseAddr[index].lstAttachment) return;
      return this.detailData.lstChooseAddr[index].lstAttachment.filter(
        (val) => val.docTypeName == name
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-content-box {
  .dialog-content {
    margin: 0 auto;
    .file-group {
      .label {
        text-align: left;
      }
      > li {
        padding-bottom: 0;
        padding-top: 0;
        margin-bottom: 17px;
      }
      .value {
        margin: 17px 10px 0;
        color: #00a0e9;
        font-size: 14px;
        display: block;
      }
      .flex {
        margin-bottom: 17px;
      }
    }
  }
  .value-txt {
    color: #374567;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>x