<template>
  <div>
    <visit-template
      v-if="
        getByActive.type != 'visit' ||
        (getByActive.type == 'visit' && typeStatus.condition == 1)
      "
      ><div
        class="btns"
        v-if="getByActive.type == 'visit' && typeStatus.condition == 1"
      >
        <div class="btn red">终止事项</div>
        <div class="btn">转成项目</div>
        <div @click="editEvent" class="btn active-color">信息更改</div>
      </div></visit-template
    >
    <visit-add-template v-else>
      <div class="btns">
        <el-button
          :class="isClick ? '' : 'unClick'"
          type="primary"
          @click="onSubmit('formData')"
          >确认</el-button
        >
        <el-button @click="close">取 消</el-button>
      </div>
    </visit-add-template>
  </div>
</template>

<script>
import visitAddTemplate from "@/views/workbench_module/attract_project/components/add/visit";
import visitTemplate from "@/views/workbench_module/attract_project/components/detail/visit";

import { mapGetters } from "vuex";
export default {
  components: {
    visitAddTemplate,
    visitTemplate,
  },
  computed: {
    ...mapGetters(["getByActive"]),
    typeStatus() {
      return this.$store.getters.getByType(1);
    },
  },
  methods: {
    onSubmit() {
      this.$store.dispatch("visit/submit").then(() => {
        this.$store.commit("SET_DIALOGVISIBLE", false);
        this.$store.dispatch("getList");
      });
    },
    editEvent() {
      this.$store.commit("SET_CONDITION", {
        val: 3,
      });
      this.$store.dispatch("getList");
    },
    close() {
      this.$store.commit("SET_DIALOGVISIBLE", false);
    },
  },
};
</script>

<style lang="sass" scoped>
</style>