
<template>
  <div class="dialog-content-box content-create-detail">
    <div class="dialog-content" v-if="detailData">
      <div
        v-for="(item, index) in reverseList(detailData)"
        :key="item.id"
        class="border item marb"
      >
        <div class="title">拜访考察{{ detailData.length - index }}</div>
        <ul class="file-group content" v-if="item.lstAttachment">
          <li class="flex">
            <div class="label">项目人员：</div>
            <div class="value-txt">{{ item.cstName }}</div>
          </li>
          <li class="flex">
            <div class="label">陪同人员：</div>
            <div class="value-txt">{{ item.receptionist }}</div>
          </li>
          <div class="flex">
            <li class="flex-1 flex">
              <div class="label">时间：</div>
              <div class="value-txt">{{ item.time }}</div>
            </li>
            <li class="flex-1 flex">
              <div class="label">拜访考察事由：</div>
              <div class="value-txt">{{ item.reason }}</div>
            </li>
          </div>
          <li v-for="lab in labels" :key="lab.id">
            <div class="label">{{ lab.name }}</div>
            <a
              class="value active-color"
              :href="staticURL + i.fileName"
              v-for="i in dealData(index, lab.name)"
              :key="i.fileName"
              >{{ i.originalName }}</a
            >
          </li>
          <li>
            <div class="label">备注：</div>
            <div class="value value-txt">{{ item.remark }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="dialog-footer">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { URL } from "@/util/request";
import { mapState } from "vuex";
export default {
  data() {
    return {
      companyListFiles: [],
      contentListFiles: [],
      staticURL: URL.static,
    };
  },
  computed: {
    ...mapState({
      detailData: (state) => state.visit.detailData,
      labels: (state) => state.visit.labels,
    }),
  },
  mounted() {
    this.$store.dispatch("visit/getVisit");
  },
  methods: {
    reverseList(data) {
      let list = JSON.parse(JSON.stringify(data));
      return list.reverse();
    },
    dealData(index, name) {
      if (!this.detailData[index].lstAttachment) return;
      const list = this.detailData[index].lstAttachment.filter(
        (val) => val.docTypeName == name
      );
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-content-box {
  .dialog-content {
    margin: 0 auto;
    .file-group {
      .label {
        text-align: left;
      }
      > li {
        padding-bottom: 0;
        padding-top: 0;
        margin-bottom: 17px;
      }
      .value {
        margin: 17px 10px 0;
        color: #00a0e9;
        font-size: 14px;
        display: block;
      }
      > .flex {
        margin-bottom: 17px;
      }
      .value-txt {
        color: #374567;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
</style>