<template>
  <div class="dialog-content-box content-create-detail">
    <div
      class="dialog-content"
      v-if="
        detailData.projectInfo && Object.keys(detailData.projectInfo).length > 0
      "
    >
      <div class="border-bottom">
        <div class="flex marb">
          <div class="flex-1">
            <span class="lable">项目名称：</span>
            <span class="value">{{ detailData.projectInfo.projName }}</span>
          </div>
          <div>
            <span class="lable">最新状态：</span>
            <span class="value">{{
              filterStatus(detailData.projectInfo.status - 1)
            }}</span>
            <!-- <span>项目登记</span> -->
          </div>
        </div>
        <div class="marb">
          <span class="lable">主要建设内容：</span>
          <span class="value">{{ detailData.projectInfo.intro }}</span>
        </div>
        <div class="marb flex">
          <span class="lable">附件：</span>
          <div>
            <div v-for="item in contentListFiles" :key="item.originalName">
              <a class="active-color" :href="staticUrl + item.fileName">{{
                item.originalName
              }}</a>
            </div>
          </div>
        </div>
        <div class="flex company-file marb">
          <div>公司简介:</div>
          <div>
            <div v-for="item in companyListFiles" :key="item.originalName">
              <a class="active-color" :href="staticUrl + item.fileName">{{
                item.originalName
              }}</a>
            </div>
          </div>
        </div>
        <div class="list-end">
          <span class="lable">备注：</span>
          <span class="value">{{ detailData.projectInfo.remark }}</span>
        </div>
      </div>
      <div class="border-bottom">
        <div class="border">
          <div class="title">企业信息</div>
          <div class="content">
            <div class="flex">
              <div class="flex-1">
                <div class="item">
                  <span class="lable">企业名称：</span>
                  <span class="value">{{
                    detailData.projectInfo.entName
                  }}</span>
                </div>
                <div class="item">
                  <span class="lable">成立时间：</span>
                  <span class="value">-</span>
                </div>
                <div>
                  <span class="lable">所属产业：</span>
                  <span class="value"
                    >{{ detailData.projectInfo.industry
                    }}{{ detailData.projectInfo.industryDesc }}</span
                  >
                </div>
              </div>
              <div>
                <div class="item">
                  <span class="lable">服务领域：</span>
                  <span class="value">{{
                    detailData.projectInfo.serviceArea
                  }}</span>
                </div>
                <div class="item">
                  <span class="lable">综合评分：</span>
                  <span class="value">-</span>
                </div>
                <div class="item">
                  <span class="lable">地址</span>
                  <span class="value">-</span>
                </div>
              </div>
            </div>
            <div
              v-if="
                detailData.lstEnterprise &&
                detailData.lstEnterprise[0].lstShareHolder.length > 0
              "
            >
              <div
                class="border flex-top-margin"
                v-for="(item, index) in detailData.lstEnterprise[0]
                  .lstShareHolder"
                :key="item.id"
              >
                <div class="title-2 border-bottom">市外股东{{ index + 1 }}</div>
                <div class="flex content">
                  <div class="flex-1">
                    <div class="item">
                      <span class="lable">市外股东：</span>
                      <span class="value">{{ item.holderName }}</span>
                    </div>
                    <div>
                      <span class="lable">来自地区：</span>
                      <span class="value">{{ item.sourceArea }}</span>
                    </div>
                  </div>
                  <div>
                    <span class="lable">股东占比：</span>
                    <span class="value">{{ item.investRatio }}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="border-bottom"
        v-if="detailData.lstCutomer && detailData.lstCutomer.length > 0"
      >
        <div
          class="linkman border border-title"
          v-for="(item, index) in detailData.lstCutomer"
          :key="item.name"
        >
          <div class="title">企业联系人{{ index + 1 }}</div>
          <div class="flex content">
            <div class="flex-1">
              <div class="item">
                <span class="lable">联系人姓名：</span>
                <span class="value">{{ item.cstName }}</span>
              </div>
              <div>
                <span class="lable">联系方式：</span>
                <span class="value">{{ item.tel }}</span>
              </div>
            </div>
            <div class="flex-1">
              <span class="lable">联系人职务：</span>
              <span class="value">{{ item.position }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="project-info border-bottom">
        <div class="border">
          <div class="title">项目信息</div>
          <div class="content">
            <div class="item">
              <span class="lable">项目优先级：</span>
              <span class="value">{{ detailData.projectInfo.priority }}</span>
            </div>
            <div class="flex item">
              <div class="flex-1">
                <div class="item">
                  <span class="lable">我方负责人：</span>
                  <span class="value">{{ detailData.projectInfo.fzr }}</span>
                </div>
                <div class="item">
                  <span class="lable">经办人：</span>
                  <span class="value">{{ detailData.projectInfo.jbr }}</span>
                </div>
                <div class="item">
                  <span class="lable">渠道来源：</span>
                  <span class="value"
                    >{{ detailData.projectInfo.channelSource }} &nbsp;{{
                      detailData.projectInfo.channelSourceDesc
                    }}</span
                  >
                </div>
                <div>
                  <span class="lable">产业类型：</span>
                  <span class="value"
                    >{{ detailData.projectInfo.industry }} &nbsp;{{
                      detailData.projectInfo.industryDesc
                    }}</span
                  >
                </div>
              </div>
              <div>
                <div class="item">
                  <span class="lable">接触时间：</span>
                  <span class="value">{{
                    detailData.projectInfo.contractTime
                  }}</span>
                </div>
                <div class="item">
                  <span class="lable">计划投资额：</span>
                  <span class="value">{{
                    detailData.projectInfo.investAmount
                  }}</span>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="item flex-1">
                <div>
                  <span class="lable">项目引荐人：</span>
                  <span class="value">{{ detailData.projectInfo.yjr }}</span>
                </div>
              </div>
              <div>
                <div>
                  <span class="lable">项目推荐人所属部门：</span>
                  <span class="value">{{
                    detailData.projectInfo.yjrDept
                  }}</span>
                </div>
              </div>
            </div>
            <div
              v-if="
                detailData.projectInfo.lstRecDept &&
                detailData.projectInfo.lstRecDept.length > 0
              "
            >
              <div
                class="border marb"
                v-for="(item, index) in detailData.projectInfo.lstRecDept"
                :key="item.id"
              >
                <div class="title-2 border-bottom">引荐部室{{ index + 1 }}</div>
                <div class="content">
                  <div class="flex">
                    <div class="flex-1">
                      <div class="item">
                        <span class="lable">共同引荐部室：</span>
                        <span class="value">{{ item.deptName }}</span>
                      </div>
                      <div>
                        <span class="lable">联系人姓名：</span>
                        <span class="value">{{ item.lxr }}</span>
                      </div>
                    </div>
                    <div>
                      <div class="item">
                        <span class="lable">分配比例：</span>
                        <span class="value">{{ item.assignRatio }}</span>
                      </div>
                      <div>
                        <span class="lable">联系人职务：</span>
                        <span class="value">{{ item.lxrPosition }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-footer">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { URL } from "@/util/request";
import { mapState } from "vuex";
export default {
  data() {
    return {
      staticUrl: URL.static,
      companyListFiles: [],
      contentListFiles: [],
    };
  },
  computed: {
    ...mapState({
      detailData: (state) => state.projectRegister.detailData,
    }),
    detailData() {
      this.companyListFiles = [];
      this.contentListFiles = [];
      let detailData = this.$store.state.projectRegister.detailData;
      if (detailData.lstAttachment && detailData.lstAttachment.length > 0) {
        this.companyListFiles = detailData.lstAttachment.filter(
          (x) => x.docTypeId == "2"
        );
        this.contentListFiles = detailData.lstAttachment.filter(
          (x) => x.docTypeId == "1"
        );
      }
      return detailData;
    },
  },
  methods: {
    filterStatus(index) {
      if (this.$store.state.step.list[index]) {
        return this.$store.state.step.list[index].name;
      }
    },
  },
  mounted() {
    this.$store.dispatch("getProjectInfo");
  },
};
</script>

<style lang="scss" scoped>
.linkman {
  margin-bottom: 20px;
}
.linkman:last-child {
  margin-bottom: 0;
}
.dialog-content-box {
  padding: 24px 0 0;
  .dialog-content {
    margin: 0 auto;
    .project-title {
      text-align: center;
    }
  }
}
::v-deep .el-dialog {
  width: 75%;
  .el-dialog__body {
    padding: 0;
  }
}
.label {
  width: 200px;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  color: rgba(55, 69, 103, 0.75);
}
.value {
  width: 42px;
  font-size: 14px;
  font-weight: 500;
  color: #374567;
}

.marb {
  margin-bottom: 26px;
}
.flex {
  .list-end {
    margin-bottom: 0;
  }
}
.flex-top-margin {
  margin-top: 26px;
}
.border-bottom {
  margin-bottom: 26px;
  padding-bottom: 26px;
}
.title-2.border-bottom {
  padding: 10px;
  margin-bottom: 0px;
}
.company-file {
  > div {
    flex: inherit;
  }
}
.el-button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 2px;
}
</style>