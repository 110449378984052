<template>
  <div>
    <site-select-template
      v-if="
        getByActive.type != 'site-select' ||
        (getByActive.type == 'site-select' && typeStatus.condition == 1)
      "
      ><div
        class="btns"
        v-if="getByActive.type == 'site-select' && typeStatus.condition == 1"
      >
        <div class="btn red">终止事项</div>
        <div class="btn">转成项目</div>
        <div @click="editEvent" class="btn active-color">信息更改</div>
      </div></site-select-template
    >
    <site-select-add-template v-else>
      <div class="btns">
        <el-button
          :class="isClick ? '' : 'unClick'"
          type="primary"
          @click="onSubmit('formData')"
          >确认</el-button
        >
        <el-button @click="close">取 消</el-button>
      </div>
    </site-select-add-template>
  </div>
</template>

<script>
import siteSelectAddTemplate from "@/views/workbench_module/attract_project/components/add/site-select";
import siteSelectTemplate from "@/views/workbench_module/attract_project/components/detail/site-select";

import { mapGetters } from "vuex";
export default {
  components: {
    siteSelectAddTemplate,
    siteSelectTemplate,
  },
  computed: {
    ...mapGetters(["getByActive"]),
    typeStatus() {
      return this.$store.getters.getByType(2);
    },
  },
  methods: {
    onSubmit() {
      this.$store.dispatch("site-select/submit").then(() => {
        this.$store.commit("SET_DIALOGVISIBLE", false);
        this.$store.dispatch("getList");
      });
    },
    editEvent() {
      this.$store.commit("SET_CONDITION", {
        val: 3,
      });
      this.$store.dispatch("getList");
    },
    close() {
      this.$store.commit("SET_DIALOGVISIBLE", false);
    },
  },
};
</script>

<style lang="sass" scoped>
</style>