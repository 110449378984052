<template>
  <div>
    <register-template
      v-if="
        getByActive.type != 'register' ||
        (getByActive.type == 'register' && typeStatus.condition == 1)
      "
    >
      <div
        class="btns"
        v-if="getByActive.type == 'register' && typeStatus.condition == 1"
      >
        <div class="btn red">终止事项</div>
        <div class="btn">转成项目</div>
        <div @click="editEvent" class="btn active-color">信息更改</div>
      </div>
    </register-template>
    <register-add-template v-else>
      <div class="btns">
        <el-button
          :class="isClick ? '' : 'unClick'"
          type="primary"
          @click="onSubmit('formData')"
          >确认</el-button
        >
        <el-button @click="close">取 消</el-button>
      </div>
    </register-add-template>
  </div>
</template>

<script>
import registerTemplate from "../components/detail/register";
import registerAddTemplate from "../components/add/register";

import { mapGetters } from "vuex";
export default {
  name: "register",
  components: {
    registerTemplate,
    registerAddTemplate,
  },
  computed: {
    ...mapGetters(["getByActive"]),
    typeStatus() {
      return this.$store.getters.getByType(0);
    },
  },
  methods: {
    editEvent() {
      this.$store.commit("SET_CONDITION", {
        val: 3,
      });
      this.$store.dispatch('getList');
    },
    onSubmit() {
      this.$store.dispatch("submitFrom").then(() => {
        this.$store.commit("SET_DIALOGVISIBLE", false);
        this.$store.dispatch('getList');
      });
    },
    close() {
      this.$store.commit("SET_DIALOGVISIBLE", false);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>