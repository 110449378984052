<template>
  <div>
    <div class="dialog-content">
      <el-form :model="formData" ref="form" label-width="150px" :validate-on-rule-change='false'>
        <div class="mab">
          <div class="flex">
            <div class="flex-1">
              <el-form-item
                label="项目名称："
                :label-width="'130px'"
                prop="projectInfo.projName"
                :rules="[{ required: true, message: '项目名称不能为空' }]"
              >
                <el-input v-model="formData.projectInfo.projName"></el-input>
              </el-form-item>
            </div>
            <el-form-item label="最新状态：" prop="name">
              <el-input disabled value="项目登记"></el-input>
            </el-form-item>
          </div>
          <el-form-item
            label="主要建设内容："
            :label-width="'180px'"
            :rules="[{ required: true, message: '主要建设内容不能为空' }]"
            prop="projectInfo.intro"
          >
            <el-input
              type="textarea"
              v-model="formData.projectInfo.intro"
            ></el-input>
          </el-form-item>
          <div class="flex">
            <el-form-item :label-width="'180px'" class="flex-1">
              <el-upload
                class=""
                :action="url"
                ref="a"
                :on-success="
                  (response, file, fileList) => handleSuccess(file, '1')
                "
                :on-remove="handleRemove"
                :file-list="contentListFiles | fileDeal"
              >
                <div class="active-color border addBtn">+上传附件</div>
              </el-upload>
            </el-form-item>
          </div>
          <div class="flex">
            <el-form-item
              label="公司简介"
              :label-width="'180px'"
              class="flex-1"
            >
              <el-upload
                class=""
                :action="url"
                :on-success="
                  (response, file, fileList) => handleSuccess(file, '2')
                "
                :on-remove="handleRemove"
                :file-list="companyListFiles | fileDeal"
              >
                <div class="active-color border addBtn">+上传附件</div>
              </el-upload>
            </el-form-item>
          </div>
          <el-form-item
            label="备注："
            :label-width="'180px'"
            prop="projectInfo.remark"
          >
            <el-input
              type="textarea"
              v-model="formData.projectInfo.remark"
            ></el-input>
          </el-form-item>
        </div>
        <add-company
          :data="formData.lstEnterprise && formData.lstEnterprise[0]"
          @addshareholder="changeForm"
          @removeShareholder="removeForm"
        >
        </add-company>
        <add-linkman
          :data="formData.lstCutomer"
          @addLinkman="changeForm"
          @removeLinkman="removeForm"
        ></add-linkman>
        <add-project-info
          :data="formData.projectInfo"
          @addRecommend="changeForm"
          @removeDept="removeForm"
        ></add-project-info>
      </el-form>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Company from "./company";
import LinkMan from "./linkMan";
import ProjectInfo from "./projectInfo";
import { URL } from "@/util/request";
export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formData() {
      let detailData = this.$store.state.projectRegister.detailData;

      if (detailData.lstAttachment && detailData.lstAttachment.length > 0) {
        this.companyListFiles = detailData.lstAttachment.filter(
          (x) => x.docTypeId == "2"
        );
        this.contentListFiles = detailData.lstAttachment.filter(
          (x) => x.docTypeId == "1"
        );
      }
      detailData.projectInfo.lstRecDept = this.emptyDataFilter({
        data: detailData.projectInfo.lstRecDept,
        emptyData: [
          {
            deptName: "",
            assignRatio: "",
            lxr: "",
            lxrPosition: "",
          },
        ],
      });
      detailData.lstEnterprise[0].lstShareHolder = this.emptyDataFilter({
        data: detailData.lstEnterprise[0].lstShareHolder,
        emptyData: [
          {
            sourceArea: "", //来自地区
            holderName: "", //市外股东
            investRatio: "", //股东占比
          },
        ],
      });
      detailData.lstCutomer = this.emptyDataFilter({
        data: detailData.lstCutomer,
        emptyData: [
          {
            cstName: "",
            position: "",
            tel: "",
          },
        ],
      });
      return detailData;
    },
  },

  data() {
    return {
      url: URL.API_ATTACHMENT_UPLOAD,
      companyListFiles: [],
      contentListFiles: [],
    };
  },
  filters: {
    fileDeal(data) {
      //数据处理
      data.forEach((val) => {
        val.name = val.originalName;
        val.url = URL.static + val.fileName;
      });
      return data;
    },
  },
  components: {
    "add-company": Company,
    "add-linkman": LinkMan,
    "add-project-info": ProjectInfo,
  },
  methods: {
    emptyDataFilter({ data, emptyData }) {
      return data.length > 0 ? data : emptyData;
    },
    clearFiles() {
      this.$refs["my-upload"].clearFiles();
    },
    handleRemove(file) {
      const filterIndex = this.formData.lstAttachment.findIndex(
        (x) => x.uid == file.uid
      );
      this.formData.lstAttachment.splice(filterIndex, 1);
    },
    removeForm(type) {
      if (!isNaN(type.index) && type.child) {
        this.formData[type.name][type.index][type.child].splice(type.i, 1);
        return;
      } else if (type.child) {
        this.formData[type.name][type.child].splice(type.i, 1);
      } else {
        this.formData[type.name].splice(type.i, 1);
      }
    },
    handleSuccess(file, value) {
      this.formData.lstAttachment.push({
        originalName: file.name,
        fileName: file.response.data,
        docTypeId: value,
        uid: file.uid,
        name: file.name,
        url: URL.static + file.fileName,
      });
    },
    changeForm(type) {
      if (!isNaN(type.index) && type.child) {
        this.formData[type.name][type.index][type.child].push(type.data);
        return;
      } else if (type.child) {
        const list = [...this.formData[type.name][type.child], type.data];
        this.$set(this.formData[type.name], type.child, list);
      } else {
        this.formData[type.name].push(type.data);
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.mab {
  margin-bottom: 30px;
}
.dialog-content {
  .el-form {
    margin: 0 auto;
  }
  .addBtn {
    height: 30px;
    line-height: 30px;
    margin-top: 0;
    padding: 0;
    font-size: 14px;
  }
  .company-info {
    .company-introduce {
      margin-bottom: 10px;
    }
  }
}
::v-deep .el-input__inner {
  height: 30px !important;
}
::v-deep .el-dialog {
  width: 70%;
  .el-dialog__body {
    padding: 0;
  }
}
::v-deep .el-upload {
  width: 100%;
}
</style>