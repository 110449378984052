<template>
  <div>
    <el-form :model="formData" :label-width="'130px'">
      <div class="flex">
        <el-form-item
          class="flex-1"
          label="选址类型："
          prop="landType"
          :rules="[{ required: true, message: '选址类型不能为空' }]"
        >
          <el-select v-model="formData.landType"  placeholder="选址类型">
            <el-option v-for="item in options" :label="item.label" :value="item.value" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="面积需求："
          prop="area"
          :rules="[{ required: true, message: '面积需求不能为空' }]"
        >
          <el-input v-model="formData.area"></el-input>
        </el-form-item>
      </div>
      <div
        class="border marb content-create-detail"
        v-for="(item, index) in formData.lstChooseAddr"
        :key="index"
      >
        <div class="title flex flex-between">
          <div>选址信息{{ index + 1 }}</div>
          <div
            class="active-color"
            v-if="index != 0"
            @click="removeForm(index)"
          >
            删除
          </div>
        </div>
        <div class="content">
          <div>
            <el-form-item
              class="flex-1"
              label="载体/土地名称："
              :prop="`lstChooseAddr.${index}.addrName`"
              :rules="[{ required: true, message: '载体/土地名称不能为空' }]"
            >
              <div>
                <el-input v-model="item.addrName"></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="flex">
            <div class="flex-1">
              <el-form-item
                label="时间："
                :rules="[{ required: true, message: '时间不能为空' }]"
              >
                <div>
                  <el-date-picker
                    v-model="item.time"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item
                label="实测面积："
                :prop="`lstChooseAddr.${index}.area`"
                :rules="[{ required: true, message: '实测面积不能为空' }]"
              >
                <div>
                  <el-input v-model="item.area"></el-input>
                </div>
              </el-form-item>
            </div>
            <el-form-item
              class="flex-1"
              label="位置:"
              :prop="`lstChooseAddr.${index}.location`"
              :rules="[{ required: true, message: '位置不能为空' }]"
            >
              <div>
                <el-input v-model="item.location"></el-input>
              </div>
            </el-form-item>
          </div>
          <el-form-item
            v-for="lab in labels"
            :key="lab.id"
            :label="lab.name"
            class="flex-1 attachment-item"
            :rules="[
              {
                required: lab.required ? true : false,
                message: `${lab.name}不能为空`,
              },
            ]"
          >
            <el-upload
              :action="url"
              :on-success="
                (response, file, fileList) =>
                  handleSuccess(file, lab.name, index)
              "
              :on-remove="
                (response, file, fileList) => handleRemove(response, index)
              "
              :file-list="formData.lstChooseAddr[index].lstAttachment | fileDeal"
            >
              <div class="active-color border addBtn">添加附件</div>
            </el-upload>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div>
      <div class="active-color border addBtn" @click="addForm">+添加</div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { URL } from "@/util/request";
import { mapState } from "vuex";
import { getAttachment } from "@/api/crm/workbench";
export default {
  data() {
    return {
      url: URL.API_ATTACHMENT_UPLOAD,
      labels: [
        {
          name: "选址图件",
          id: 1,
        },
      ],
      options:[
        {
          label:'征地类',
          value:1
        },
        {
          label:'现有厂房入驻类',
          value:2
        },
        {
          label:'平台类',
          value:3
        }
      ]
    };
  },
  computed: {
    ...mapState({
      formData: (state) => state["site-select"].detailData,
    }),
  },
  filters: {
    fileDeal(data) {
      //数据处理
      data.forEach((val) => {
        val.name = val.originalName;
        val.url = URL.static + val.fileName;
      });
      return data;
    },
  },
  methods: {
    clearFiles() {
      this.$refs["my-upload"].clearFiles();
    },
    handleRemove(file,index) {
      const filterIndex = this.formData.lstChooseAddr[index].lstAttachment.findIndex(
        (x) => x.uid == file.uid
      );
      this.formData.lstChooseAddr[index].lstAttachment.splice(filterIndex, 1);
    },

    handleSuccess(file, value, index) {
      getAttachment({ name: value }).then((res) => {
        if (res.status == 200) {
          if (! this.formData.lstChooseAddr[index].lstAttachment) {
             this.formData.lstChooseAddr[index].lstAttachment = [];
          }
           this.formData.lstChooseAddr[index].lstAttachment.push({
            originalName: file.name,
            fileName: file.response.data,
            docTypeId: res.data,
            uid: file.uid,
            name: file.name,
          });
        }
      });
    },
    removeForm(index) {
      console.log(index)
      this.$store.commit('site-select/REMOVE_ADDRESS',index);
    },
    addForm() {
      this.$store.commit("site-select/ADD_ADDRESS");
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.active-color {
  cursor: pointer;
}
.addBtn {
  height: 30px;
  line-height: 30px;
  margin-top: 0;
}
.title {
  background: #f6f8fd;
  color: #374567;
  font-size: 14px;
  padding: 8px 24px;
  margin-bottom: 10px;
}
.content {
  padding: 16px 12px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
::v-deep .el-date-editor--date .el-input__inner {
  border-color: #aab0be;
  height: 40px !important;
  border-radius: 5px;
  padding: 10px auto;
}
.mab {
  margin-bottom: 30px;
}
::v-deep .el-upload {
  width: 100%;
}
</style>