<template>
  <div class="company mab">
    <div class="title">企业信息</div>
    <div class="content" v-if="Object.keys(data).length>0">
      <div class="flex">
        <div class="flex-1">
          <el-form-item
            label="企业简称："
            :rules="[{ required: true, message: '企业简称不能为空' }]"
            prop="lstEnterprise[0].shortName"
          >
            <el-input v-model="data.shortName"></el-input>
          </el-form-item>
          <el-form-item
            label="企业全称："
            prop="lstEnterprise[0].entName"
            :rules="[{ required: true, message: '企业全称不能为空' }]"
            class="flex-1"
          >
            <el-input v-model="data.entName"></el-input>
          </el-form-item>
          <div class="flex">
            <el-form-item
              label="所属产业："
              prop="lstEnterprise[0].industry"
              :rules="[{ required: true, message: '所属产业不能为空' }]"
            >
              <el-select v-model="data.industry" placeholder="所属产业">
                <el-option label="石油装备" value="石油装备"></el-option>
                <el-option label="海工装备" value="海工装备"></el-option>
                <el-option label="精密铸造" value="精密铸造"></el-option>
                <el-option label="高端装备" value="高端装备"></el-option>
                <el-option label="生物医药" value="生物医药"></el-option>
                <el-option label="新能源" value="新能源"></el-option>
                <el-option label="低碳科技" value="低碳科技"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="data.industry == '其他'"
              label=""
              label-width="0"
              prop="lstEnterprise[0].industry"
              :rules="[{ required: true, message: '所属产业不能为空' }]"
            >
              <el-input v-model="data.industryDesc"></el-input>
            </el-form-item>
          </div>
        </div>
        <el-form-item
          label="服务领域："
          prop="lstEnterprise[0].serviceArea"
          :rules="[{ required: true, message: '服务领域不能为空' }]"
        >
          <el-input v-model="data.serviceArea"></el-input>
        </el-form-item>
      </div>
      <div
        class="border share-holder-item"
        v-for="(item, index) in data.lstShareHolder"
        :key="item.name"
      >
        <div>
          <div class="flex flex-between border-bottom share-holder-item-title">
            <div>市外股东{{ index + 1 }}</div>

            <div
              class="active-color delete"
              v-if="index != 0"
              @click="removeShareHolder(index)"
            >
              删除
            </div>
          </div>
          <div class="flex flex-between holderName-item">
            <div>
              <el-form-item label="市外股东：" :prop="'lstEnterprise.0.lstShareHolder.'+index+'.holderName'">
                <el-input v-model="item.holderName"></el-input>
              </el-form-item>
              <el-form-item label="来自地区：" :prop="'lstEnterprise.0.lstShareHolder.'+index+'.sourceArea'">
                <el-input v-model="item.sourceArea"></el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="股东占比：" :prop="'lstEnterprise.0.lstShareHolder.'+index+'.investRatio'">
                <el-input v-model="item.investRatio"></el-input>
              </el-form-item>
              <!-- <div class="active-color delete" v-if="index != 0" @click="removeShareHolder(index)">删除</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="active-color border addBtn" @click="addShareHolder">
        +添加市外股东
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    addShareHolder() {
      this.$emit("addshareholder", {
        name: "lstEnterprise",
        index: 0,
        child: "lstShareHolder",
        data: {
          holderName: "",
          investRatio: "",
          sourceArea: "",
        },
      });
    },
    removeShareHolder(i) {
      this.$emit("removeShareholder", {
        name: "lstEnterprise",
        index: 0,
        child: "lstShareHolder",
        i: i,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.company {
  border: 1px solid #aab0be;
  .title {
    background: #f6f8fd;
    color: #374567;
    font-size: 14px;
    padding: 8px 24px;
    margin-bottom: 10px;
  }
  .content {
    padding: 16px 12px;
  }
}
::v-deep .el-input__inner {
  height: 30px !important;
}
::v-deep .el-select {
  margin-right: 10px;
}
.active-color {
  cursor: pointer;
}
.delete {
  text-align: right;
}
.share-holder-item{
  margin-bottom: 20px;
  .holderName-item {
    margin-top: 10px;
    padding-right: 24px;
  }
  .share-holder-item-title{
    padding: 10px;
  }
}
</style>