<template>
  <div class="projectInfo">
    <div class="title">项目信息</div>
    <div class="content">
      <el-form-item
        label="项目优先级："
        prop="projectInfo.priority"
        label-width="160px"
        :rules="[{ required: true, message: '项目优先级不能为空' }]"
      >
        <el-select placeholder="优先级" v-model="data.priority">
          <el-option label="A" value="A"></el-option>
          <el-option label="B" value="B"></el-option>
          <el-option label="C" value="C"></el-option>
          <el-option label="D" value="D"></el-option>
        </el-select>
      </el-form-item>
      <div class="flex">
        <div class="flex-1">
          <el-form-item
            label="我方负责人："
            :rules="[{ required: true, message: '我方负责人不能为空' }]"
            prop="projectInfo.fzr"
          
          >
            <el-input v-model="data.fzr"></el-input>
          </el-form-item>
          <el-form-item label="经办人：" prop="projectInfo.jbr" class="flex-1">
            <el-input v-model="data.jbr"></el-input>
          </el-form-item>
          <el-form-item
            label="线索渠道来源："
            prop="projectInfo.channelSource"
            label-width="180px"
            :rules="[{ required: true, message: '线索渠道不能为空' }]"
          >
            <div class="flex">
              <el-select v-model="data.channelSource" placeholder="线索渠道">
                <el-option label="企业" value="企业"></el-option>
                <el-option label="机构" value="机构"></el-option>
                <el-option label="顾问" value="顾问"></el-option>
                <el-option label="内部" value="内部"></el-option>
              </el-select>
              <el-input v-model="data.channelSourceDesc"></el-input>
            </div>
          </el-form-item>
          <el-form-item
            label="产业类型："
            prop="projectInfo.industry"
            :rules="[{ required: true, message: '产业类型不能为空' }]"
          >
            <div class="flex">
              <el-select placeholder="产业类型" v-model="data.industry">
                <el-option label="石油装备" value="石油装备"></el-option>
                <el-option label="海工装备" value="海工装备"></el-option>
                <el-option label="精密铸造" value="精密铸造"></el-option>
                <el-option label="高端装备" value="高端装备"></el-option>
                <el-option label="生物医药" value="生物医药"></el-option>
                <el-option label="新能源" value="新能源"></el-option>
                <el-option label="低碳科技" value="低碳科技"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
              <el-input
                v-if="data.industry == '其他'"
                v-model="data.industryDesc"
              ></el-input>
            </div>
          </el-form-item>
        </div>
        <div>
          <el-form-item
            label="接触时间："
            prop="projectInfo.contractTime"
            :rules="[{ required: true, message: '接触时间不能为空' }]"
          >
            <el-date-picker
              v-model="data.contractTime"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="投资金额：" prop="projectInfo.investAmount">
            <el-input v-model="data.investAmount"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="flex flex-between">
        <el-form-item
          label="项目引荐人："
          prop="projectInfo.yjr"
          :rules="[{ required: true, message: '项目引荐人不能为空' }]"
        >
          <el-input v-model="data.yjr"></el-input>
        </el-form-item>
        <el-form-item
          label="项目推荐人所属部门："
          prop="projectInfo.yjrDept"
          label-width="280px"
          :rules="[{ required: true, message: '项目推荐人所属部门不能为空' }]"
        >
          <el-input v-model="data.yjrDept"></el-input>
        </el-form-item>
      </div>
      <div
        class="border recommend-item-group"
        v-for="(item, index) in data.lstRecDept"
        :key="index"
      >
        <div>
          <div class="flex flex-between border-bottom recommend-item-title">
            <div>引荐部室{{ index + 1 }}</div>

            <div
              class="active-color delete"
              v-if="index != 0"
              @click="removeDept(index)"
            >
              删除
            </div>
          </div>
          <div class="flex recommend-item">
            <div class="flex-1">
              <el-form-item label="共同引荐部室：" label-width="180px" :prop="'projectInfo.lstRecDept.'+index+'.deptName'">
                <el-input v-model="item.deptName"></el-input>
              </el-form-item>
              <el-form-item label="联系人姓名：" :prop="'projectInfo.lstRecDept.'+index+'.lxr'">
                <el-input v-model="item.lxr"></el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="分配比例：" :prop="'projectInfo.lstRecDept.'+index+'.assignRatio'">
                <el-input v-model="item.assignRatio"></el-input>
              </el-form-item>
              <el-form-item label="联系人职务：" :prop="'projectInfo.lstRecDept.'+index+'.lxrPosition'">
                <el-input v-model="item.lxrPosition"></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="data.lstRecDept && data.lstRecDept.length < 3"
        class="active-color border addBtn"
        @click="addRecommend"
      >
        +添加引荐部室（最多添加3个）
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
    },
  },
  computed:{
    dataInfo(){
      return this.data;
    }
  },
  methods: {
    addRecommend() {
      this.$emit("addRecommend", {
        name: "projectInfo",
        child: "lstRecDept",
        data: {
          deptName: "",
          assignRatio: "",
          lxr: "",
          lxrPosition: "",
        },
      });
    },
    removeDept(i) {
      this.$emit("removeDept", {
        name: "projectInfo",
        child: "lstRecDept",
        i: i,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.projectInfo {
  border: 1px solid #aab0be;
  .title {
    background: #f6f8fd;
    color: #374567;
    font-size: 14px;
    padding: 8px 24px;
    margin-bottom: 10px;
  }
  .content {
    padding: 16px 12px;
  }
}
::v-deep .el-input__inner {
  height: 30px !important;
}
::v-deep .el-select {
  margin-right: 10px;
}
.recommend-item-group {
  margin-bottom: 20px;
  .recommend-item {
    margin-top: 10px;
    padding-right: 24px;
  }
  .recommend-item-title {
    padding: 10px;
  }
}
</style>