<template>
  <div class="dialog-content-box content-create-detail">
    <div
      class="dialog-content"
      v-if="
        detailData.projectInfo && Object.keys(detailData.projectInfo).length > 0
      "
    >
      <div class="border-bottom">
        <div class="flex marb">
          <div class="flex-1">
            <span class="lable">项目名称：</span>
            <span class="value">{{ detailData.projectInfo.projName }}</span>
          </div>
          <div>
            <span class="lable">最新状态：</span>
            <span class="value">{{ filterStatus(detailData.projectInfo.status-1) }}</span>
          </div>
        </div>
        <div class="marb">
          <span class="lable">主要建设内容：</span>
          <span class="value">{{ detailData.projectInfo.intro }}</span>
        </div>
        <div class="marb flex">
          <span class="lable">附件：</span>
          <div>
            <div v-for="item in contentListFiles" :key="item.originalName">
              <a class="active-color" :href="staticUrl + item.fileName">{{
                item.originalName
              }}</a>
            </div>
          </div>
        </div>
        <div class="flex company-file marb">
          <div>公司简介:</div>
          <div>
            <div v-for="item in companyListFiles" :key="item.originalName">
              <a class="active-color" :href="staticUrl + item.fileName">{{
                item.originalName
              }}</a>
            </div>
          </div>
        </div>
        <div class="list-end">
          <span class="lable">备注：</span>
          <span class="value">{{ detailData.projectInfo.remark }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { URL } from "@/util/request";
export default {
  data() {
    return {
      staticUrl: URL.static,
      companyListFiles: [],
      contentListFiles: [],
    };
  },
  computed: {
    detailData() {
      this.companyListFiles = [];
      this.contentListFiles = [];
      let detailData = this.$store.state.projectRegister.detailData;
      if (detailData.lstAttachment && detailData.lstAttachment.length > 0) {
        this.companyListFiles = detailData.lstAttachment.filter(
          (x) => x.docTypeId == "2"
        );
        this.contentListFiles = detailData.lstAttachment.filter(
          (x) => x.docTypeId == "1"
        );
      }
      return detailData;
    },
  },
  methods: {
    filterStatus(index) {
      return this.$store.state.step.list[index].name;
    },
  },
  mounted() {
    this.$store.dispatch("getProjectInfo");
  },
};
</script>

<style lang="scss" scoped>
.linkman {
  margin-bottom: 20px;
}
.linkman:last-child {
  margin-bottom: 0;
}
.dialog-content-box {
  padding: 24px 0;
  .dialog-content {
    margin: 0 auto;
    .project-title {
      text-align: center;
    }
  }
}
::v-deep .el-dialog {
  width: 75%;
  .el-dialog__body {
    padding: 0;
  }
}
.label {
  width: 200px;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  color: rgba(55, 69, 103, 0.75);
}
.value {
  width: 42px;
  font-size: 14px;
  font-weight: 500;
  color: #374567;
}

.marb {
  margin-bottom: 26px;
}
.flex {
  .list-end {
    margin-bottom: 0;
  }
}
.flex-top-margin {
  margin-top: 26px;
}
.border-bottom {
  margin-bottom: 26px;
  padding-bottom: 26px;
}
.title-2.border-bottom {
  padding: 10px;
  margin-bottom: 0px;
}
.company-file {
  > div {
    flex: inherit;
  }
}
.el-button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 2px;
}
</style>